
.bd_editBtn {
  width: 58px;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* box-shadow: 4px 5px 6px 7px rgba(0,0,0,.5);  */
}

.bd_editBtn svg {
  /* fill: #926dea; */
  fill: #722ed1;
}

.bd_editBtn:hover {
  background-color: #ffe58f
}

.bd_opSelected {
  background-color: #ffe58f;
}

.bd_editBtn .bd_iconContainer {
  width: 30px;
  height: 30px;
  position: relative;
}

.bd_editBtn .bd_iconContainer .bd_icon {
  display: block;
  width: 28px;
  height: 28px;
}

.bd_editBtn .bd_iconContainer .bd_iconText {
  display: block;
  width: 28px;
  height: 28px;
}

.bd_editBtn .bd_iconContainer .bd_triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 4px solid #9b9b9b;
  right: 0;
  bottom: 0;
}

.bd_editBtn .bd_iconContainer .bd_colorCircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  bottom: 1px;
  right: 6px;
}

.bd_editBar {
  position: fixed;
  width: 100%;
  /* max-width: 600px; */
  height: 64px;
  background-color: #ffffff;
  box-shadow: -3px 0 13px 0 rgba(0, 0, 0, 0.08);
  padding: 0 60px;
  justify-content: center;
  /* margin-top: 5px; */
  margin-left: auto;
  margin-right: auto;
  text-align: "center";

  /* display: flex; */
  z-index: 5;
}



.bd_editBar .barLeft {
  margin-right: 80px;
  display: flex;
}

.bd_editBar .barCenter {
  
  display: flex;
}


.bd_editBar .barMiddle {
  margin-right: 150px;
  display: flex;
}

.bd_customMenu .menuItem{
  display: flex;
  min-width: 56px;
  /* width: 56px; */
  width: 100%;
  align-items: center;
}

.bd_customMenu .itemIcon {
  width: 18px;
  height: 18px;
}

.bd_customMenu .itemIcon svg {
  /* fill: #d9d9d9; */
  fill: #595959;
}

.bd_customMenu .itemIcon .straightLine {
  width: 17px;
  /* border: solid 1px #d9d9d9; */
  border: solid 1px #595959;
  margin-top: 8px;
}

.bd_customMenu .line_1 {
  width: 100%;
  border: solid 1px #595959;
  margin-top: 6px;
  margin-bottom: 6px;
}

.bd_customMenu .line_2 {
  width: 100%;
  border: solid 2px #595959;
  margin-top: 6px;
  margin-bottom: 6px;
}

.bd_customMenu .line_3 {
  width: 100%;
  border: solid 3px #595959;
  margin-top: 5px;
  margin-bottom: 5px;
}

.bd_customMenu .itemIcon .rect {
  width: 12px;
  height: 12px;
  border: solid 1px #595959;
  margin-top: 2px;
}

.bd_customMenu .itemIcon .circle {
  margin-top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 7.5px;
  border: solid 1px #595959;
}

.bd_customMenu .itemIcon .ellipse {
  margin-top: 2px;
  width: 14px;
  height: 10px;
  border-radius: 5px;
  border: solid 1px #595959;
}

/* .bd_customMenu .itemIcon .itemText {
  margin-left: 8px;
  padding-left: 5px;
  color: #a5a5a5;
} */

.bd_customMenu .menuItem .itemText {
  /* margin-left: 8px; */
  padding-left: 2px;
  padding-right: 2px;
  /* color: #a5a5a5; */
  color: #595959;
}

.bd_customMenu .ant-dropdown-menu-item .itemIcon svg, 
.bd_customMenu .ant-dropdown-menu-item .itemIcon .rect, 
.bd_customMenu .ant-dropdown-menu-item .itemIcon .ellipse, 
.bd_customMenu .ant-dropdown-menu-item .itemIcon .itemText, 
:hover {
  fill: #9b9b9b;
  /* fill: #ffe58f */
}

.bd_colorMenu {
  width: 208px;
	height: 32px;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.bd_colorMenu .colorOl {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.bd_colorMenu .colorOl .colorLi {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 8px;
  text-align: center;
  list-style-type:none;
}

/* .bd_colorMenu .colorOl .colorLi svg{
  width: 10px;
  height: 10px;
} */

.bd_colorMenu .colorOl .colorLi:last-child{
  border: solid 0.5px #979797;
}

.bd_whiteBoard {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.bd_whiteBoard .sketchPad {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  /* min-height: 1080px; */
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;

  /* border-radius: 20%; */
}

.bd_whiteBoard .sketchPad2 {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  /* min-height: 1080px; */
  bottom: 0;
  left: 0;
  right: 0;

  /* border-radius: 20%; */
}

.bd_whiteBoard .sketchPad .sketchCanvas {
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 100%;

  border: solid 0.3em #979797;
  border-radius: 1em;
  /* background: #FFFFF0; */
}

.bd_whiteBoard .sketchPad2 .sketchCanvas {
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 100%;

  border: solid 0.3em #979797;
  border-radius: 1em;
  /* background: #FFFFF0; */
}

.bd_canvasTextArea {
  position: absolute;
  display: none;
  width: 200px;
  height: 100px;
  z-index: 10;
}

.bd_courseware_preview_img {
  max-width: 380px; 
  max-height: 320px;
  /* width: auto; */
  /* height: auto; */
 
}

.bd_slide_preview {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 64px;
  margin-bottom: 100px;
  height: 95%;
}


.ant-menu-light{
      background-color:'#ffe58f' !important;
  }

.slide_preview_list {
  margin-bottom: 20px;
}

